import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ItemModel, { convertToItemModel, getDefaultItem } from './ItemModel';
import { APP_NAME } from './constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';


interface LandingProps {
    setItem: (x: ItemModel) => void;
}

const createGetRequest = () => {
    const request = {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };
    return request;
}


const invokeGetApi = (endpoint: string, onResponseReceived: (x: any) => void, onError: (x: any) => void) => {
    const request = createGetRequest();
    fetch(endpoint, request)
        .then(rawResponse => rawResponse.json())
        .then(response => onResponseReceived(response))
        .catch(error => onError(error));
}

const getRecentItems = (setRecentItems: any, setHasLoadedItems: (x: boolean) => void) => {

    const onItemsLoaded = (x: any) => {
        console.log("Received recent items...");
        const items: Array<object> = JSON.parse(x.body).items;
        console.log(items);

        const recentItems: Array<ItemModel> = [];
        for (let i = 0; i < items.length; i++) {
            const parsedItem: ItemModel = convertToItemModel(items[i]);
            recentItems.push(parsedItem);
        }
        setRecentItems(recentItems);
        setHasLoadedItems(true);
    }

    invokeGetApi("https://genapi.flourishbytina.com.au/getRecent", onItemsLoaded, console.log);
}

const createRecentItemList = (recentItems: Array<ItemModel>, setItem: (x: ItemModel) => void, routerHistory: any): JSX.Element => {

    const itemElements = [];

    for (let i = 0; i < recentItems.length; i++) {
        const itemModel: ItemModel = recentItems[i];
        const itemName: string = `${itemModel.name1} and ${itemModel.name2}`;
        const idBadge = <div><span className="badge">{itemModel.pk?.substring(0, 8)}</span></div>;
        const label = <div style={{ marginTop: "auto", textAlign: "right" }}>{itemName}</div>;

        let newBadge = null;
        if (itemModel.is_new) {
            newBadge = <div style={{ marginLeft: "1rem" }}><span className="badge badge-danger">NEW</span></div>;
        }

        const element: JSX.Element = <li
            className="list-group-item list-group-item-action"
            onClick={() => {
                setItem(itemModel);
                routerHistory.push("/");
            }}
            key={i}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div style={{ marginTop: "auto", textAlign: "left", display: "flex" }}>
                    {idBadge}
                    {newBadge}
                </div>

                {label}
            </div>
        </li>
        itemElements.push(element);
    }

    return <div>

        <div>
            <h4>Recently Edited</h4>
            <p className="text-muted">Click on it to load.</p>
        </div>

        <ul className="list-group">
            {itemElements}
        </ul>

    </div>;
}

const createLoadingElement = () => {
    return <div className="alert alert-info" style={{ padding: "3rem" }}>
        <div style={{ margin: "auto" }}>
            <div className="spinner-border" role="status" style={{ marginBottom: "2rem" }}>
                <span className="sr-only">Loading...</span>
            </div>
            <h5>Loading History</h5>
            <p>Please wait...</p>
        </div>
    </div>;
}

const Landing: React.FC<LandingProps> = (props) => {

    const routeHistory = useHistory();
    const [hasLoadedItems, setHasLoadedItems] = React.useState(false);
    const [recentItems, setRecentItems] = React.useState(new Array<ItemModel>());

    useEffect(() => {
        if (hasLoadedItems === false) {
            console.log(hasLoadedItems);
            getRecentItems(setRecentItems, setHasLoadedItems);
        }
    }, [hasLoadedItems]);

    let recentItemsElement: JSX.Element | null = null;
    if (recentItems.length > 0) {
        recentItemsElement = createRecentItemList(recentItems, props.setItem, routeHistory);
    }

    let loadingElement: JSX.Element | null = null;
    if (hasLoadedItems === false) {
        loadingElement = createLoadingElement();
    }

    const newItemButton = <button
        style={{ minWidth: "320px" }}
        className="btn btn-primary btn-lg"
        onClick={() => { props.setItem(getDefaultItem()); routeHistory.push("/"); }}
    ><div style={{ float: "left", position: "absolute" }}><FontAwesomeIcon icon={faPlus} /></div>
    <div>Create New Item</div></button>;

    return <div style={{ width: "100%" }}>

        <div className="card">
            <div className="card-header">
                <h2>{APP_NAME}</h2>
            </div>

            <div className="card-body" style={{ minHeight: "50vh" }}>
                {loadingElement}
                {recentItemsElement}
            </div>


            <div className="card-footer" style={{ padding: "1.5rem" }}>
                {newItemButton}
            </div>
        </div>
    </div>
}

export default Landing;