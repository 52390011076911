import React from 'react';

interface ImagePreviewProps {
    imageLink: string;
    isLoading: boolean;
    imageNotes?: string;
}

const ImagePreview: React.FC<ImagePreviewProps> = (props) => {

    const imageDiv = props.imageLink.length > 0 ? <div style={{ marginTop: "1rem" }}>
        <img src={props.imageLink} alt="generated preview" style={{ maxWidth: "100%", maxHeight: "100%", border: "solid 2px #000" }} />
    </div> : null;

    let generatingPreview: JSX.Element | null = null;

    if (props.isLoading) {
        generatingPreview = <div className="text-muted">
            <div className="spinner-border spinner-border-sm" role="status" style={{ marginRight: "0.6em" }}>
                <span className="sr-only" />
            </div>
            {"Generating..."}
        </div>;
    }

    return <><div className="card-body">
        <h5>Preview</h5>
        {generatingPreview}
        {imageDiv}
        <div style={{ marginTop: "1rem" }} className="text-muted">{props.imageNotes}</div>
    </div>
    </>;
}

export default ImagePreview;