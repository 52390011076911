import React from 'react';
import FormField, { FieldType } from './ContactFormField';
import ContactFormCheckbox from './FormCheckbox';
import ImagePreview from './ImagePreview';
import FormDropdown from './FormDropdown';
import { convertUnit } from './UnitConverter';
import copy from 'clipboard-copy';
import { useHistory } from 'react-router-dom';
import { APP_NAME } from './constants';
import ItemModel, { getDefaultItem } from './ItemModel';
import { faFolderOpen, faPlus, faDownload, faEnvelope, faCopy, faArrowLeft, faPowerOff, faExpand, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generateMailBody, generateMailTemplate } from '../MailTemplateGenerator';
import CanvasPresetModal from './CanvasPresetModal';


export enum FormState {
    READY,
    UPDATING,
    PENDING,
    SUCCESS,
    FAILED,
    INVALID,
    PREVIEW
}

const UNIT_INCH: string = "IN";
const UNIT_PIXEL: string = "PX";
const UNIT_CM: string = "CM";

interface ImageApiProps {

    customer_name: string;
    customer_email: string;
    customer_order_id: string;

    name1: string;
    name2: string;
    ratio: string;
    width: number;
    height: number;
    offset_x: string;
    offset_y: string;
    key: string | null;
    with_swashes: boolean;
    heart_style: string;

    useSubtitle: boolean;
    subtitle: string;
    subtitle_at_bottom: boolean;
    subtitle_centered_to_heart: boolean;
    subtitle_offset_x: string;
    subtitle_offset_y: string;
    subtitle_font_ratio: string;
    subtitle_font: string;

    setFormState(x: FormState): void;
    setDownloadLink(x: string): void;
    setPreviewLink(x: string): void;
}

const generateImagePreview = (props: ImageApiProps) => {
    submitImageGenerationRequest(props, true);
}

const getPreviewDimensions = (x: number, y: number) => {

    const maxWidth: number = 400;
    let newWidth = x;
    let newHeight = y;

    if (x > maxWidth) {
        const ratio: number = maxWidth / x;
        newWidth = maxWidth;
        newHeight = Math.round(ratio * y);
    }

    return {
        width: newWidth,
        height: newHeight,
    };
}

const submitImageGenerationRequest = (props: ImageApiProps, isPreview: boolean = false) => {

    if (!isPreview) {
        props.setFormState(FormState.PENDING);
    }

    const { width, height } = isPreview ? getPreviewDimensions(props.width, props.height) : { width: props.width, height: props.height };

    const payload: object = {
        auto_save: !isPreview,

        customer_name: props.customer_name,
        customer_email: props.customer_email,
        customer_order_id: props.customer_order_id,

        name1: props.name1,
        name2: props.name2,
        key: (props.key !== null && props.key.length > 0) ? props.key : undefined,
        ratio: props.ratio,
        width: width.toString(),
        height: height.toString(),
        offset_x: props.offset_x,
        offset_y: props.offset_y,
        with_swashes: props.with_swashes,
        heart_style: props.heart_style,

        subtitle: props.subtitle,
        subtitle_at_bottom: props.subtitle_at_bottom,
        subtitle_centered_to_heart: props.subtitle_centered_to_heart,
        subtitle_offset_x: props.subtitle_offset_x,
        subtitle_offset_y: props.subtitle_offset_y,
        subtitle_font_ratio: props.subtitle_font_ratio,
        subtitle_font: props.subtitle_font,
        file_extension: "jpg"
    };

    console.log("Evoke with payload");
    console.log(payload);

    const handlerFunction = isPreview ? onGeneratePreviewResponse : onGenerateImageResponse;
    invokePostApi("https://genapi.flourishbytina.com.au/imageGeneration", payload, x => handlerFunction(x, props), x => onContactFormError(x, props));
    // setTimeout(() => {props.setFormState(FormState.SUCCESS); props.setDownloadLink("https://google.com")}, 1000);
}

const handleResponse = (response: any, props: ImageApiProps): string | null => {
    console.log(response);
    const eventBody = JSON.parse(response.body);
    const publicUrl = eventBody["public_url"];
    const shortUrl = eventBody["short_url"];
    console.log(publicUrl);
    console.log(shortUrl);

    if (response.statusCode && response.statusCode === 200) {
        return shortUrl;
    } else {
        props.setFormState(FormState.FAILED);
        return null;
    }
}

const onGeneratePreviewResponse = (response: any, props: ImageApiProps) => {
    const result: string | null = handleResponse(response, props);
    if (result) {
        props.setPreviewLink(result);
        props.setFormState(FormState.READY);
    }
}

const onGenerateImageResponse = (response: any, props: ImageApiProps) => {
    const result: string | null = handleResponse(response, props);
    if (result) {
        props.setDownloadLink(result);
        props.setFormState(FormState.SUCCESS);
    }
}

const onContactFormError = (response: object, props: ImageApiProps) => {
    console.log(response);
    props.setFormState(FormState.FAILED);
}

const createPostRequest = (payload: object) => {
    const request = {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ body: payload })
    };
    console.log(request);
    return request;
}


const invokePostApi = (endpoint: string, payload: object, onResponseReceived: (x: any) => void, onError: (x: any) => void) => {
    console.log("Getting from API: " + endpoint);
    const request = createPostRequest(payload);
    fetch(endpoint, request)
        .then(rawResponse => rawResponse.json())
        .then(response => onResponseReceived(response))
        .catch(error => onError(error));
}

const createContactFormField = (label: string, type: FieldType, isDisabled: boolean, hook: [any, (x: any) => void], updatePreview: () => void): [any, JSX.Element] => {
    const [value, setValue] = hook;
    const contactFormField = <FormField
        updatePreview={updatePreview}
        label={label}
        type={type}
        onUpdateValue={setValue}
        value={value}
        disabled={isDisabled} />;

    return [value, contactFormField];
}

const createCheckbox = (label: string, isDisabled: boolean, hook: [boolean, (x: boolean) => void], updatePreview: () => void): [boolean, JSX.Element] => {
    const [value, setValue] = hook;

    const checkbox = <ContactFormCheckbox
        updatePreview={updatePreview}
        label={label}
        disabled={isDisabled}
        onUpdateValue={setValue}
        value={value} />

    return [value, checkbox];
}

const createDropdown = (label: string, isDisabled: boolean, hook: [string, (x: string) => void], options: string[], updatePreview: () => void, isBig?: boolean): [string, JSX.Element] => {
    const [value, setValue] = hook;

    const dropdownElement = <FormDropdown
        updatePreview={updatePreview}
        label={label}
        disabled={isDisabled}
        onUpdateValue={setValue}
        options={options}
        isBig={isBig}
        value={value} />

    return [value, dropdownElement];
}

const createInfoBanner = (formState: FormState): JSX.Element => {

    if (formState === FormState.SUCCESS) {
        const message: string = "Success!";
        return <span className="text-success">{message}</span>;
    }

    if (formState === FormState.FAILED || formState === FormState.INVALID) {
        const message: string = formState === FormState.FAILED ? "Error occured: Please try again." : "Invalid input";
        return <span className="text-danger">{message}</span>;
    }

    if (formState === FormState.PENDING) {
        const message: string = "Loading";
        return <div className="text-muted">
            <div className="spinner-border spinner-border-sm" role="status" style={{ marginRight: "0.6em" }}>
                <span className="sr-only" />
            </div>
            {message}
        </div>;
    }

    return <></>;
}

const renderFormHeader = (routerHistory: any, setItem: (x: ItemModel) => void, isDisabled: boolean): JSX.Element => {

    const iconElement = <FontAwesomeIcon icon={faFolderOpen} />;
    const backButton = <button className="btn btn-outline-secondary"
        type="button"
        style={{ fontSize: "1.2rem" }}
        onClick={() => routerHistory.push("/files")}
        disabled={isDisabled}
    >{iconElement}</button>;


    const newIconElement = <FontAwesomeIcon icon={faPlus} />;
    const newButton = <button className="btn btn-outline-secondary"
        type="button"
        style={{ fontSize: "1.2rem" }}
        onClick={() => { setItem(getDefaultItem()); routerHistory.push("/new"); }}
        disabled={isDisabled}
    >{newIconElement}</button>;

    return <div style={{ display: "flex" }}>
        <div style={{ float: "left", marginTop: "auto", marginBottom: "auto" }}>{backButton}</div>
        <div style={{ margin: "auto" }}><h2>{APP_NAME}</h2></div>
        <div style={{ float: "right", marginTop: "auto", marginBottom: "auto" }}>{newButton}</div>

    </div>;
}

const parseRatio = (x: string | number) => {
    if (x) {
        return (parseFloat(x.toString()) / 100.0).toString();
    } else {
        return "0";
    }
}

interface ContactFormProps {
    item: ItemModel;
    setItem(x: ItemModel): void;
}

let timer: any;

const ContactFormJSX: React.FC<ContactFormProps> = (props) => {

    // React Hooks.
    const [formState, setFormState] = React.useState(FormState.READY);
    const [isLinkCopied, setIsLinkCopied] = React.useState(false);
    const [shouldUpdatePreview, setShouldUpdatePreview] = React.useState(true);
    const [downloadLink, setDownloadLink] = React.useState("");
    const [previewLink, setPreviewLink] = React.useState("");
    const isDisabled: boolean = formState !== FormState.READY && formState !== FormState.UPDATING && formState !== FormState.FAILED;
    const item = props.item;

    const units: string[] = [UNIT_INCH, UNIT_CM, UNIT_PIXEL];
    const [previousUnit, setPreviousUnit] = React.useState(item.default_unit);
    const [width, setWidth] = React.useState(item.width);
    const [height, setHeight] = React.useState(item.height);
    const [isCanvasModalOpen, setIsCanvasModalOpen] = React.useState(false);
    const routerHistory = useHistory();

    const updatePreview = () => {
        setShouldUpdatePreview(true);
    }

    // Fields
    const [name1, name1Field] = createContactFormField("Name 1", FieldType.Name, isDisabled, React.useState(item.name1), updatePreview);
    const [name2, name2Field] = createContactFormField("Name 2", FieldType.Name, isDisabled, React.useState(item.name2), updatePreview);
    const widthField = createContactFormField("W", FieldType.Size, isDisabled, [width, setWidth], updatePreview)[1];
    const heightField = createContactFormField("H", FieldType.Size, isDisabled, [height, setHeight], updatePreview)[1];
    const [ratio, ratioField] = createContactFormField("Content Width", FieldType.Ratio, isDisabled, React.useState(item.text_center_ratio), updatePreview);
    const [xOffset, xOffsetField] = createContactFormField("X", FieldType.Ratio, isDisabled, React.useState(item.offset_x), updatePreview);
    const [yOffset, yOffsetField] = createContactFormField("Y", FieldType.Ratio, isDisabled, React.useState(item.offset_y), updatePreview);
    const [sizeUnit, sizeUnitField] = createDropdown("", isDisabled, React.useState(item.default_unit), units, () => { }, true);
    const [withSwashes, withSwashesField] = createCheckbox("With swashes", isDisabled, React.useState<boolean>(item.with_swashes), updatePreview);

    const [withSubtitle, subtitleCheckbox] = createCheckbox("Show subtitle", isDisabled, React.useState<boolean>(item.subtitle.length > 0), updatePreview);
    const [subtitleText, subtitleTextField] = createContactFormField("Text", FieldType.Name, isDisabled, React.useState(item.subtitle), updatePreview);
    const [alignToHeart, alignToHeartCheckbox] = createCheckbox("Align to Heart", isDisabled, React.useState<boolean>(item.subtitle_centered_to_heart), updatePreview);
    const [alignToBottom, alignToBottomCheckbox] = createCheckbox("Align to Bottom", isDisabled, React.useState<boolean>(item.subtitle_at_bottom), updatePreview);
    const [xSubOffset, xSubOffsetField] = createContactFormField("X", FieldType.Ratio, isDisabled, React.useState(item.subtitle_offset_x), updatePreview);
    const [ySubOffset, ySubOffsetField] = createContactFormField("Y", FieldType.Ratio, isDisabled, React.useState(item.subtitle_offset_y), updatePreview);
    const [fontSizeRatio, fontSizeRatioField] = createContactFormField("Font Ratio", FieldType.Ratio, isDisabled, React.useState(item.subtitle_font_ratio), updatePreview);

    const fonts: string[] = ["gillsans", "orator"];
    const [subtitleFont, setSubtitleFont] = createDropdown("Subtitle Font", isDisabled, React.useState(item.subtitle_font), fonts, updatePreview, false);

    const heartStyles: string[] = ["normal", "small", "mini", "ring"];
    const [heartStyle, heartStyleField] = createDropdown("Heart Style", isDisabled, React.useState(item.heart_style), heartStyles, updatePreview, false);


    // Mailing and Template Section.
    const [customerEmail, customerEmailField] = createContactFormField("Email", FieldType.Name, isDisabled, React.useState(item.customer_email), () => { });
    const [customerName, customerNameField] = createContactFormField("Name", FieldType.Name, isDisabled, React.useState(item.customer_name), () => { });
    const [orderNumber, customerOrderField] = createContactFormField("Order #", FieldType.Name, isDisabled, React.useState(item.customer_order_id), () => { });

    const mailBody = generateMailBody(customerName, downloadLink);
    const mailTemplate = generateMailTemplate(customerEmail, customerName, orderNumber, downloadLink);

    if (sizeUnit !== previousUnit) {
        setHeight(convertUnit(height, previousUnit, sizeUnit));
        setWidth(convertUnit(width, previousUnit, sizeUnit));
        setPreviousUnit(sizeUnit);
    }

    const widthPx: number = convertUnit(width, sizeUnit, UNIT_PIXEL);
    const heightPx: number = convertUnit(height, sizeUnit, UNIT_PIXEL);

    const formProps: ImageApiProps = {

        customer_name: customerName,
        customer_email: customerEmail,
        customer_order_id: orderNumber,

        name1: name1.toString(),
        name2: name2.toString(),
        key: item.pk,
        width: widthPx,
        height: heightPx,
        ratio: parseRatio(ratio),
        offset_x: parseRatio(xOffset),
        offset_y: parseRatio(yOffset),
        with_swashes: withSwashes,
        heart_style: heartStyle,

        useSubtitle: withSubtitle,
        subtitle: withSubtitle ? subtitleText : null,
        subtitle_at_bottom: alignToBottom,
        subtitle_centered_to_heart: alignToHeart,
        subtitle_offset_x: parseRatio(xSubOffset),
        subtitle_offset_y: parseRatio(ySubOffset),
        subtitle_font_ratio: parseRatio(fontSizeRatio),
        subtitle_font: subtitleFont,
        setFormState: setFormState,
        setDownloadLink: setDownloadLink,
        setPreviewLink: setPreviewLink
    }

    if (shouldUpdatePreview && name1.length > 0 && name2.length > 0 && widthPx > 0 && heightPx > 0) {
        if (timer) { clearTimeout(timer); }
        timer = setTimeout(() => {
            setFormState(FormState.UPDATING);
            generateImagePreview(formProps);
        }, 2000);
        setShouldUpdatePreview(false);
        setFormState(FormState.UPDATING);
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        submitImageGenerationRequest(formProps);
        setIsLinkCopied(false);
    }

    let infoBanner: JSX.Element = createInfoBanner(formState);

    let infoBox = null;
    let downloadButton = null;
    let backToEditButton = null;
    let submitButton = null;
    let copyToClipboardButton = null;
    let prepareEmailButton = null;

    const itemMetaData = <div>
        <span className="badge badge-secondary">{item.pk?.substr(0, 8)}</span></div>;

    if (formState === FormState.READY || formState === FormState.UPDATING || formState === FormState.FAILED) {
        const submitDisabled = isDisabled || formState === FormState.UPDATING;
        submitButton = <button style={{ minWidth: "320px" }} className="btn btn-primary btn-lg" type="button" disabled={submitDisabled} onClick={onSubmit}>
            <div style={{ float: "left", position: "absolute" }}><FontAwesomeIcon icon={faPowerOff} /></div>
            <div>Generate</div>
        </button>;
    }

    if (formState === FormState.SUCCESS) {
        backToEditButton = <button
            style={{ minWidth: "320px" }}
            className="btn btn-primary btn-lg"
            onClick={() => setFormState(FormState.READY)}
        ><div style={{ float: "left", position: "absolute" }}><FontAwesomeIcon icon={faArrowLeft} /></div>
            <div>Back to Edit</div></button>;
    }

    if (formState === FormState.SUCCESS && downloadLink.length > 0) {
        copyToClipboardButton = <button
            className="btn btn-primary btn-lg"
            style={{ minWidth: "320px", marginBottom: "1rem" }}
            type="button"
            disabled={isLinkCopied}
            onClick={() => {
                copy(downloadLink);
                setIsLinkCopied(true);
            }}
        ><div style={{ float: "left", position: "absolute" }}><FontAwesomeIcon icon={faCopy} /></div>
            <div>{isLinkCopied ? "Copied!" : "Copy Link"}</div>
        </button>

        downloadButton = <button
            className="btn btn-success btn-lg"
            style={{ minWidth: "320px", marginBottom: "1rem" }}
            type="button"
            onClick={(e) => {
                e.preventDefault();
                window.open(downloadLink, "_blank")
            }}
        ><div style={{ float: "left", position: "absolute" }}><FontAwesomeIcon icon={faDownload} /></div>
            <div>Get Image</div>
        </button>

        prepareEmailButton = <button
            className="btn btn-primary btn-lg"
            style={{ minWidth: "320px", marginBottom: "1rem" }}
            type="button"
            onClick={(e) => {
                e.preventDefault();
                copy(mailBody);
                window.location.href = mailTemplate;
            }}
        ><div style={{ float: "left", position: "absolute" }}><FontAwesomeIcon icon={faEnvelope} /></div>
            <div>Mail Template</div></button>
    }

    if (formState !== FormState.READY && formState !== FormState.UPDATING) {
        infoBox = <div style={{ marginTop: "auto", marginBottom: "1rem" }}>
            {infoBanner}
        </div>;
    }

    let subtitleOptions = <></>;

    if (withSubtitle) {
        subtitleOptions = <>
            {subtitleTextField}
            {alignToBottomCheckbox}
            {alignToHeartCheckbox}
            <div style={{ display: "flex" }}>
                {xSubOffsetField}
                {<div style={{ width: "2rem" }} />}
                {ySubOffsetField}
            </div>
            {fontSizeRatioField}
            {setSubtitleFont}
        </>;
    }

    let displayBody: JSX.Element = <></>;
    let previewBody: JSX.Element | null = null;
    let metaBody: JSX.Element | null = null;

    if (previewLink.length > 0 || formState === FormState.UPDATING) {
        const notes: string = `Size (px): ${widthPx} x ${heightPx}`;
        previewBody = <ImagePreview imageLink={previewLink} imageNotes={notes} isLoading={formState === FormState.UPDATING} />
    }

    metaBody = <>
        <div className="card-body">
            <h5>Customer Details</h5>
            {customerNameField}
            {customerEmailField}
            {customerOrderField}
        </div>
    </>;

    const setCanvasSize = (h: number, w: number) => {
        setWidth(convertUnit(w, UNIT_PIXEL, sizeUnit));
        setHeight(convertUnit(h, UNIT_PIXEL, sizeUnit));
        setShouldUpdatePreview(true);
    }

    const canvasPresetModal = <CanvasPresetModal
        isOpen={isCanvasModalOpen} setIsOpen={setIsCanvasModalOpen} setCanvasSize={setCanvasSize} />;

    const openCanvasModalButton = <button style={{ width: "100%" }} className="btn btn-secondary" type="button" onClick={() => setIsCanvasModalOpen(true)}>
        <div style={{ float: "left", position: "absolute" }}><FontAwesomeIcon icon={faExpand} /></div>
        <div>Presets</div>
    </button>;

    const swapDimensions = () => {
        setWidth(height);
        setHeight(width);
        setShouldUpdatePreview(true);
    }

    const swapDimensionsButton = <button style={{ width: "100%" }} className="btn btn-secondary" type="button" onClick={swapDimensions}>
        <div style={{ float: "left", position: "absolute" }}><FontAwesomeIcon icon={faExchangeAlt} /></div>
        <div>Swap</div>
    </button>;

    displayBody = <>
        <div className="card-body">
            {itemMetaData}
            <h5>Main Content</h5>

            <div style={{ display: "flex" }}>
                {heightField}
                {<div style={{ width: "1rem" }} />}
                {widthField}
                {<div style={{ width: "1rem" }} />}
                {sizeUnitField}
            </div>
            <div style={{ display: "flex", marginBottom: "1rem" }}>
                {openCanvasModalButton}
                {<div style={{ width: "1rem" }} />}
                {swapDimensionsButton}
            </div>

            {name1Field}
            {name2Field}
            {withSwashesField}
            {heartStyleField}
        </div>

        <div className="card-body">

            <h5>Offsets (% of Width)</h5>
            <div style={{ display: "flex" }}>
                {xOffsetField}
                {<div style={{ width: "2rem" }} />}
                {yOffsetField}
            </div>

            {ratioField}
        </div>

        <div className="card-body">
            <h5>Subtitle</h5>
            {subtitleCheckbox}
            {subtitleOptions}
        </div>
    </>;


    return <div>
        {canvasPresetModal}
        <form style={{ width: "100%" }}>
            <div className="card">
                <div className="card-header">
                    {renderFormHeader(routerHistory, props.setItem, isDisabled || formState === FormState.UPDATING)}
                </div>

                {displayBody}
                {previewBody}
                {metaBody}

                <div className="card-footer" style={{ padding: "1.5rem" }}>
                    {infoBox}
                    {downloadButton}
                    {copyToClipboardButton}
                    {prepareEmailButton}
                    {backToEditButton}
                    {submitButton}
                </div>
            </div>
        </form>
    </div>
}

export default ContactFormJSX;
