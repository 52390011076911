import React from 'react';


abstract class DisplayableElement {

    public static wrapWithDisplayableElement = (element: JSX.Element) => {
        return <div className="dev" style={{ height: "100%", padding: "3px" }}>
            <div className="container" style={{ maxWidth: "512px" }}>
                <div className="row">{element}</div>
            </div>
        </div>
    }
}

export default DisplayableElement;