const UNIT_INCH: string = "IN";
const UNIT_PIXEL: string = "PX";
const UNIT_CM: string = "CM";
const DPI: number = 300;
const INCH_TO_CM: number = 2.54;
const CM_TO_INCH: number = 1 / INCH_TO_CM;


export const convertUnit = (value: number, fromUnit: string, toUnit: string): number => {

    let result: number = value;

    if (fromUnit === toUnit) {
        return result;
    }

    if (fromUnit === UNIT_INCH && toUnit === UNIT_CM) {
        result = value * INCH_TO_CM;
    }

    if (fromUnit === UNIT_INCH && toUnit === UNIT_PIXEL) {
        result = value * DPI;
    }

    if (fromUnit === UNIT_CM && toUnit === UNIT_INCH) {
        result = value * CM_TO_INCH;
    }

    if (fromUnit === UNIT_CM && toUnit === UNIT_PIXEL) {
        result = value * CM_TO_INCH * DPI;
    }

    if (fromUnit === UNIT_PIXEL && toUnit === UNIT_INCH) {
        result = value / DPI;
    }

    if (fromUnit === UNIT_PIXEL && toUnit === UNIT_CM) {
        result = INCH_TO_CM * value / DPI;
    }

    if (toUnit === UNIT_PIXEL) {
        result = Math.round(result);
    } else {
        result = Math.round((result + Number.EPSILON) * 100) / 100
    }

    return result;
}

