
const DEFAULT_BODY_TEMPLATE_ARR: string[] = [
    "Dear $NAME,",
    "Thank you so much for your purchase!",
    "Attached is your custom print to download (",
    "Please note that as mentioned in the listing descrption, the order is for a digital file only, so no physical item will be sent. Once you have downloaded the file, simply print from home, your local print store or online print websites (e.g. www.shutterfly.com).",
    "If you have any questions, please feel free to message me :)",
    "I hope you enjoy your personalised print and thanks again for shopping with FlourishByTina.",
    "Tina x",
    "",
    "$LINK"
];

const DEFAULT_SUBJECT_TEMPLATE: string = "Etsy Order #$ORDER_ID";

export const generateMailBody = (name: string, imageLink: string) => {
    const linebreakChar: string = "\n";
    const formattedBody: string =  DEFAULT_BODY_TEMPLATE_ARR
    .join(linebreakChar + linebreakChar)
    .replace("$NAME", name)
    .replace("$LINK", imageLink);
    return `${formattedBody}`;
}

export const generateMailTemplate = (email: string, name: string, orderId: string, imageLink: string) => {
    const formattedSubject: string = DEFAULT_SUBJECT_TEMPLATE.replace("$ORDER_ID", orderId);
    const formattedBody: string =  generateMailBody(name, imageLink);
    const mailTemplate = `mailto:${email}?subject=${formattedSubject}&body=${formattedBody}`;
    return mailTemplate;
};