import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Landing from './components/Landing';
import DisplayableElement from './components/DisplayableElement';
import ItemModel, { getDefaultItem } from './components/ItemModel';
import ContactFormJSX from './components/ImageGeneratorForm';

function App() {

  const defaultItem: ItemModel = getDefaultItem();
  const [item, setItem] = React.useState<ItemModel>(defaultItem);
  // const [shouldUseNewItem, setShouldUseNewItem] = React.useState<boolean>(false);

  return (
    <div className="App">

      <Router>
        <Switch>

          <Route path="/files">
            {DisplayableElement.wrapWithDisplayableElement(<Landing setItem={setItem} />)}
          </Route>

          <Route path="/new">
            <Redirect to="/"/>
          </Route>


          <Route path="/">
            {DisplayableElement.wrapWithDisplayableElement(<ContactFormJSX item={item} setItem={setItem} />)}
          </Route>

        </Switch>
      </Router>



    </div>
  );
}

export default App;
