import React from 'react';

export enum FieldType {
    Name,
    Ratio,
    Size
}

interface FormFieldProps {
    label: string;
    type: FieldType;
    disabled: boolean;
    value?: string | number;
    onUpdateValue(x: string | number): void;
    updatePreview(): void;
}

interface InputFieldParameters {
    label: string;
    type: string;
    placeholder?: string;
}

const getParametersForType = (fieldType: FieldType): InputFieldParameters => {

    switch (fieldType) {
        case FieldType.Name:
            return { label: "Name", type: "text", placeholder: "Enter Text" };

        case FieldType.Ratio:
            return { label: "Name", type: "number", placeholder: "0" };

        case FieldType.Size:
            return { label: "Name", type: "number", placeholder: "0" };

    }
}

const FormField: React.FC<FormFieldProps> = (props) => {

    const input: InputFieldParameters = getParametersForType(props.type);
    let prependElement: JSX.Element | null = null;
    const fontSize = props.type === FieldType.Size ? "1.2rem" : "1rem";
    
    prependElement = <div className="input-group-prepend">
        <span className="input-group-text" id="basic-addon3" style={{fontSize: fontSize}}>{props.label}</span>
    </div>

    let displayValue = props.value;
    
    return <div>
        <div className="input-group mb-3">
            {prependElement}

            <input type={input.type}
                className={"form-control"}
                id={`FormField${input.type}`}
                aria-describedby={input.type}
                placeholder={input.placeholder}
                disabled={props.disabled}
                onChange={(e) => { props.onUpdateValue(e.currentTarget.value); props.updatePreview(); }}
                value={displayValue}
                style={{fontSize: fontSize}}
                step="any"
            >
            </input>

        </div>

    </div>
}

export default FormField;