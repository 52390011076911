import { v4 as uuidv4 } from 'uuid';

const DEFAULT_SUBTITLE_FONT: string = "gillsans";

interface ItemModel {

    customer_name: string;
    customer_email: string;
    customer_order_id: string;
    is_new: boolean;

    width: number;
    height: number;
    name1: string;
    name2: string;
    offset_x: number;
    offset_y: number;
    pk: string | null;
    with_swashes: boolean;
    heart_style: string;

    subtitle: string;
    subtitle_at_bottom: boolean;
    subtitle_centered_to_heart: boolean;
    subtitle_font_ratio: number;
    subtitle_offset_x: number;
    subtitle_offset_y: number;
    subtitle_font: string;
    text_center_ratio: number;
    default_unit: string;
}

const roundedMultiplyBy100 = (x: number): number => {
    return Math.round((x * 100 + Number.EPSILON) * 100) / 100
}

export const convertToItemModel = (x: object): ItemModel => {
    const item: ItemModel = x as ItemModel;

    item.offset_x = roundedMultiplyBy100(item.offset_x);
    item.offset_y = roundedMultiplyBy100(item.offset_y);
    item.subtitle_offset_x = roundedMultiplyBy100(item.subtitle_offset_x);
    item.subtitle_offset_y = roundedMultiplyBy100(item.subtitle_offset_y);
    item.subtitle_font_ratio = roundedMultiplyBy100(item.subtitle_font_ratio);
    item.text_center_ratio = roundedMultiplyBy100(item.text_center_ratio);
    item.default_unit = "PX";

    if (item.subtitle_font === undefined) {
        item.subtitle_font = DEFAULT_SUBTITLE_FONT;
    }

    if (item.with_swashes === undefined) {
        item.with_swashes = true;
    }

    if (item.customer_name === undefined) {
        item.customer_name = "";
    }

    if (item.customer_email === undefined) {
        item.customer_email = "";
    }

    if (item.customer_order_id === undefined) {
        item.customer_order_id = "";
    }

    if (item.heart_style === undefined) {
        item.heart_style = "normal";
    }

    if (item.is_new === undefined) {
        item.is_new = false;
    }

    return item;
}

export const getDefaultItem = (): ItemModel => {
    console.log("Generate Default Item");
    return {

        customer_name: "",
        customer_email: "",
        customer_order_id: "",
        is_new: false,

        width: 10,
        height: 8,
        name1: "",
        name2: "",
        offset_x: 0,
        offset_y: 0,
        pk: uuidv4().split('-').join(''),
        with_swashes: true,
        heart_style: "normal",
        subtitle: "",
        subtitle_at_bottom: false,
        subtitle_centered_to_heart: false,
        subtitle_font_ratio: 3.5,
        subtitle_offset_x: 0,
        subtitle_offset_y: 0,
        text_center_ratio: 85,
        default_unit: "IN",
        subtitle_font: DEFAULT_SUBTITLE_FONT
    };
}

export default ItemModel;