import React from 'react';

interface ContactFormCheckboxProps {
    label: string;
    disabled: boolean;
    value?: boolean;
    onUpdateValue(x: boolean): void;
    updatePreview(): void;
}

const ContactFormCheckbox: React.FC<ContactFormCheckboxProps> = (props) => {

    let prependElement: JSX.Element | null = null;
    prependElement = <div className="input-group-append">
        <span className="input-group-text" id="basic-addon3">
        <input type="checkbox" 
        disabled={props.disabled}
        defaultChecked={props.value} 
        onChange={()=> { props.onUpdateValue(!props.value); props.updatePreview(); }}
        aria-label="Checkbox for following text input"/>
        </span>
    </div>

    const textStyle = props.value ? "text-success" : "text-muted";

    return <div>

        <div className="input-group mb-3">

            <div className={"form-control text-left " + textStyle}>
                {props.label}
            </div>

            {prependElement}


        </div>

    </div>
}

export default ContactFormCheckbox;