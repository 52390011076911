import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

interface CanvasPresetModalProps {
    isOpen: boolean;
    setIsOpen: (x: boolean) => void;
    setCanvasSize: (height: number, width: number) => void;
}

interface CanvasOption {
    name: string;
    width: number;
    height: number;
}

const mmToPixels = (x: number) => {
    const pixelsPerMm: number = 11.811024;
    const pixels: number = Math.round(x * pixelsPerMm);
    return pixels;
}

const canvasButton = (canvasOption: CanvasOption, i: number, setCanvasAndClose: (h: number, w: number) => void) => {
    const canvasSize = `${canvasOption.height}mm x ${canvasOption.width}mm`
    const onClick = () => {
        setCanvasAndClose(mmToPixels(canvasOption.height), mmToPixels(canvasOption.width));
    }

    return <button
        type="button"
        className="list-group-item list-group-item-action text-right"
        key={i}
        onClick={onClick}>
        <div className="d-flex" style={{ fontSize: "1.24rem" }}>
            <div style={{ marginRight: "auto" }}><b>{canvasOption.name}</b></div>
            <div className="text-muted">{canvasSize}</div>
        </div>
    </button>;
}

const CanvasPresetModal: React.FC<CanvasPresetModalProps> = (props) => {

    const closeModal = () => props.setIsOpen(false);

    const canvasOptions: CanvasOption[] = [
        { name: "A1", height: 594, width: 841 },
        { name: "A2", height: 420, width: 594 },
        { name: "A3", height: 297, width: 420 },
        { name: "A4", height: 210, width: 297 },
    ]

    const setCanvasAndClose = (height: number, width: number) => {
        props.setCanvasSize(height, width);
        props.setIsOpen(false);
    }

    const canvasButtons = [];
    for (let i = 0; i < canvasOptions.length; i++) {
        canvasButtons.push(canvasButton(canvasOptions[i], i, setCanvasAndClose));
    }

    const presetList = <ul className="list-group">
        {canvasButtons}
    </ul>

    return <>
        <div>
            <Modal isOpen={props.isOpen} toggle={closeModal}>
                <ModalHeader toggle={closeModal}>Canvas Preset</ModalHeader>
                <ModalBody>
                    {presetList}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={closeModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    </>;
}

export default CanvasPresetModal;