import React from 'react';

interface FormDropdownProps {
    label?: string;
    isBig?: boolean;
    disabled: boolean;
    value?: string;
    onUpdateValue(x: string): void;
    options: string[];
    updatePreview(): void;
}

const FormDropdown: React.FC<FormDropdownProps> = (props) => {

    const optionsArray = [];
    const fontSize: string = props.isBig ? "1.2rem" : "1rem";

    const onSelect = (value: string) => {
        props.onUpdateValue(value);
    }

    let selectedValue: string = "";

    for (let i: number = 0; i < props.options.length; i++) {
        const optText: string = props.options[i];
        const optionElement = <option value={optText} key={i}>{optText}</option>;
        if (props.value === optText) {
            selectedValue = props.value;
        }
        optionsArray.push(optionElement);
    }

    const prependElement = props.label ? <div className="input-group-prepend">
        <span className="input-group-text" id="basic-addon3">{props.label}</span>
    </div> : null;

    return <div style={{ minWidth: "5rem" }}>
        <div className="input-group mb-3">
            {prependElement}
            <select className="custom-select form-control"
                defaultValue={selectedValue}
                style={{ fontSize: fontSize }}
                disabled={props.disabled}
                onChange={(e) => { onSelect(e.target.value); props.updatePreview(); }}>
                {optionsArray}
            </select>
        </div>
    </div>
}

export default FormDropdown;